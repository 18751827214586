import { APIResponse } from "../../service";
import {
  createBinLog,
  createDailyPrep,
  createInspectionFiveService,
  createInspectionFourService,
  createInspectionOneService,
  createInspectionSixService,
  createInspectionThreeService,
  createInspectionTwoService,
  createLotService,
  getModulePackaging,
  createPallet,
  createSortingAndBinningProcess,
} from "../../service/apiService";
import { toast } from "react-toastify";

export const successToast = (des: string) => {
  toast.success(des, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

const formConstructor = (data: any, user?: number) => {
  const formdata = new FormData();
  const dataCopy = { ...data };

  const fieldArr = Object.keys(dataCopy);

  for (let i = 0; i < fieldArr.length; i++) {
    if (fieldArr[i] === "kpi1" && dataCopy["kpi1"]) {
      dataCopy["kpi1"] = dataCopy["kpi1"].trim();
      formdata.append("battery_module", dataCopy[fieldArr[i]]);
      continue;
    }
    if (fieldArr[i] === "rejection_reason" && data[fieldArr[i]] === "") {
      continue;
    }
    if (fieldArr[i] === "attached_module_id" && data[fieldArr[i]] === " ") {
      continue;
    }
    formdata.append(fieldArr[i], data[fieldArr[i]]);
  }
  if (user) {
    formdata.append("inspector", user.toString());
  }
  return formdata;
};

export const handleCreateLot = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createLotService(formdata, authToken).then((res: APIResponse) => {
      if (res.success) {
        successToast("Lot created successfully");
      }
      return res;
    });
  }
};

export const handleCreatePallet = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createPallet(formdata, authToken).then((res: APIResponse) => {
      if (res.success) {
        successToast("Pallet created successfully");
      }
      return res;
    });
  }
};

export const handleCreateModule = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createDailyPrep(formdata, authToken).then((res: APIResponse) => {
      if (res.success) {
        successToast("Modules created successfully");
      }
      return res;
    });
  }
};

export const createElectricalInspectionOne = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    console.log("%c Line:96 🥛 data", "color:#b03734", data);
    return createInspectionOneService(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Electrical inspection one created successfully");
        }
        return res;
      }
    );
  }
};

export const createElectricalInspectionTwo = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");

  if (authToken) {
    const formdata = formConstructor(data);
    return createInspectionTwoService(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Electrical inspection two created successfully");
        }
        return res;
      }
    );
  }
};

export const createElectricalInspectionThree = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createInspectionThreeService(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Electrical inspection three created successfully");
        }
        return res;
      }
    );
  }
};

export const createElectricalInspectionFour = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createInspectionFourService(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Electrical inspection four created successfully");
        }
        return res;
      }
    );
  }
};

export const createElectricalInspectionFive = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createInspectionFiveService(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Electrical inspection five created successfully");
        }
        return res;
      }
    );
  }
};

export const createElectricalInspectionSix = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data);
    return createInspectionSixService(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Electrical inspection six created successfully");
        }
        return res;
      }
    );
  }
};

export const createBinService = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const body = {
      shipment_lot: data.shipment_lot,
      pallet: data.pallet,
      oem: data.oem,
      oem_model: data.oem_model,
      module_count: Number(data.module_count),
      storage_bin: Number(data.storage_bin),
      // inspector: user,
    };
    // const formdata = formConstructor(data, user);
    // formdata.append("storage_bin", "master-bin");
    return createBinLog(body, authToken).then((res: APIResponse) => {
      if (res.success) {
        successToast("Bin service created successfully");
      }
      return res;
    });
  }
};

export const SABProcessService = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    const formdata = formConstructor(data, user);
    return createSortingAndBinningProcess(formdata, authToken).then(
      (res: APIResponse) => {
        if (res.success) {
          successToast("Sorting and binning process created successfully");
        }
        return res;
      }
    );
  }
};

export const createModulePackagingService = (data: any, user: number) => {
  const authToken = localStorage.getItem("accessToken");
  if (authToken) {
    // const formdata = formConstructor(data);
    const body: any = {
      assembly_location: data?.assembly_location,
      number_of_packs: data?.number_of_packs,
      oem: data?.oem,
      oem_model: data?.oem_model,
      modules_per_pack: data?.modules_per_pack,
      soh_high: data?.soh_high,
      soh_low: data?.soh_low,
      assembly_contractor: data?.assembly_contractor,
      voltage: data?.voltage,
      bill_of_material: data?.bill_of_material,
    };
    return getModulePackaging(authToken, body).then((res: APIResponse) => {
      if (res.success) {
        successToast("Module package generated successfully");
      }
      return res;
    });
  }
};
