import React, { useEffect, useState } from "react";

import { NavStep } from "../../fieldComponants/NavStep";
import { Field } from "../../types";

import { useAppSelector } from "../../../../store/rtctype";
import { Step } from "../../types";
import SopHeader from "../../StepForms/sopHeader";
import { ScanField } from "../ScanField";
import InputField from "../InputField";
import SelectField from "../SelectField";
import FileField from "../FileField";
import FileReadField from "../FileReadField";
import { fieldmap, stepSop } from "../../fieldmap";
import StatusField from "../StatusField";
import CheckInput from "../CheckInput";

export default function QisForm({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const [currentActive, setCurrentActive] = useState<number>(0);

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData[step]]);

  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={fieldmap[step]?.title} link={""} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {fieldmap[step] &&
                fieldmap[step]?.fields.map((field: Field, index: number) => {
                  if (field.type === "field") {
                    return (
                      <InputField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "checkbox") {
                    return (
                      <CheckInput
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "select") {
                    return (
                      <SelectField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  } else if (field.type === "file") {
                    return (
                      <FileField
                        status={index <= currentActive}
                        data={field}
                        step={step}
                        // loading={loadingGet}
                        value={formData[step] ? formData[step][field.key] : ""}
                        key={field.key}
                      />
                    );
                  }
                  //   else if (field.type === "status") {
                  //     return (
                  //       <StatusField
                  //         status={index <= currentActive}
                  //         data={field}
                  //         loading={loading}
                  //         step={step}
                  //         value={formData[step][field.key]}
                  //         key={field.key}
                  //         //   onClick={handleSubmit}
                  //       />
                  //     );
                  //   } else if (field.type === "reset") {
                  //     return (
                  //       <NavStep
                  //         step={step}
                  //         data={field}
                  //         status={index <= currentActive}
                  //         key={field.key}
                  //         //   onClick={handleSubmit}
                  //       />
                  //     );
                  //   }

                  return null;
                })}
            </div>
          </div>
        </div>
      </div>

      {/* <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      /> */}
    </React.Fragment>
  );
}
