import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

interface MyProps { index: number }

const Footer: React.FC<MyProps> = ({ index }) => {
    const footerOpt = ["Intake", "Inspection", "Assembly", "Deployment", "Reverse Logistics", "Recycling"]
    const styles = StyleSheet.create({
        bootomBar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginTop: 10,
            justifyContent: "center"

        },
        input3: {
            width: "16.6666666%",
            height: "25px",
            borderTop: "1px solid black",
            borderRight: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            alignSelf:"center"
        },
        activeDate: {
            backgroundColor: "black",
            color: "white"
        },
        FooterText:{
            fontSize: 10,
            textAlign:"center"
        }
    })
    return (
        <View style={styles.bootomBar}>
            {
                footerOpt?.map((opt, ind) => {
                    return (
                        index === ind ? <View style={{ ...styles.input3, ...styles.activeDate }}><Text style={styles.FooterText}>{opt}</Text></View> : <View style={styles.input3}>
                            <Text style={styles.FooterText}>{opt}</Text>
                        </View>
                    )
                })
            }
        </View>

    )
}

export default Footer