import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import imag from "../../../../../assets/images/dva.png"
function PageTop() {
    const styles = StyleSheet.create({
        section1: {
            flexDirection: "column",
            width: "95%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            paddingLeft: 10,
            paddingRight: 10
        },
        headText: {
            fontSize: 10,
            letterSpacing: 1.5,
            color: "#3498d2",
            fontWeight: 900,
        },
        section2: {
            marginTop: 20,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "20px"
        },
        sideImage: {
            height: "300px",
            width:"200px"
        },
        rightSide: {
            width: "50%",
            flexDirection: "column"
        },
        inputs: {
            display: "flex",
            justifyContent: "flex-end",
            flexDirection: "row",
            gap: 10,
            alignItems: "center",
            width: "100%",
            marginTop: 5
        },
        input: {
            width: "100px",
            height: "30px",
            border: "1px solid black"
        },
        input2: {
            width: "80px",
            height: "25px",
            border: "1px solid black"
        },
        inputText: {
            fontSize: 12,
            fontWeight: 700
        },
        inputText2: {
            fontSize: 10,
            fontWeight: 700
        }
    })
    return (
        <View style={styles.section1}>
            <Text style={styles.headText}>NthLife <Text>TM</Text> Battery Passport</Text>
            <Text style={styles.headText}>Serial Number: XXXXXX</Text>

            <View style={styles.section2}>
                <Image src={imag} style={styles.sideImage} />
                <View style={styles.rightSide}>
                    <View style={styles.inputs}>
                        <Text style={styles.inputText}>Make</Text>
                        <Text style={styles.input}></Text>
                    </View>
                    <View style={styles.inputs}>
                        <Text style={styles.inputText}>Model</Text>
                        <Text style={styles.input}></Text>
                    </View>
                    <View style={styles.inputs}>
                        <Text style={styles.inputText}>Chemistry</Text>
                        <Text style={styles.input}></Text>
                    </View>
                    <View style={styles.inputs}>
                        <View style={{ width: "80px", flexDirection:"column", display:"flex" }}>
                            <Text style={styles.inputText}>Voltage</Text>
                            <Text style={styles.inputText}>Ranges</Text>
                        </View>
                        <View>
                            <View style={styles.inputs}>
                                <Text style={styles.inputText2}>Max</Text>
                                <Text style={styles.input2}></Text>
                            </View>
                            <View style={styles.inputs}>
                                <Text style={styles.inputText2}>Nominal</Text>
                                <Text style={styles.input2}></Text>
                            </View>
                            <View style={styles.inputs}>
                                <Text style={styles.inputText2}>Min</Text>
                                <Text style={styles.input2}></Text>
                            </View>
                        </View>

                    </View>
                    <View style={styles.inputs}>
                        <Text style={styles.inputText}>Rated Capacity</Text>
                        <Text style={styles.input}></Text>
                    </View>
                </View>
            </View>
        </View>
    )
}

export default PageTop