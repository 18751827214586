import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import imag from "../../../../../assets/images/dva.png"
import Footer from './Footer'
function Intake() {
    const styles = StyleSheet.create({
        section1: {
            flexDirection: "column",
            width: "95%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            border: "2px solid black",
            paddingTop: 10,
        },
        rightSide: {
            width: "80%",
            flexDirection: "column",
            marginTop:5
        },
        inputs: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 30,
            alignItems: "center",
            width: "100%",
            marginTop: 5
        },
        input: {
            width: 200,
            height: "30px",
            border: "1px solid black"
        },
        input2: {
            width: 200,
            height: "25px",
            border: "1px solid black"
        },
        input3: {
            width: "16.6666666%",
            height: "25px",
            borderTop: "1px solid black",
            borderRight: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"row"
        },
        activeDate: {
            backgroundColor: "black",
            color: "white"
        },
        inputText: {
            fontSize: 12,
            fontWeight: 700
        },
        inputText2: {
            fontSize: 10,
            fontWeight: 700
        },
        intakeHead: {
            fontSize: 20,
            fontWeight: 900
        },
        intakeImg: {
            height: 200,
            width: 200
        },
        bootomBar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginTop: 10,
           
        }
    })
    return (
        <View style={styles.section1}>
            <Text style={styles.intakeHead}>Battery Inspection Intake</Text>
            <View style={styles.rightSide}>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Reverse Logistics Supply Date </Text>
                    <Text style={styles.input}></Text>
                </View>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Inspection Site</Text>
                    <Text style={styles.input}></Text>
                </View>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Inspection intake Lot # </Text>
                    <Text style={styles.input}></Text>
                </View>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Inspection intake Lot Image </Text>
                    <Image src={imag} style={styles.intakeImg} />
                </View>

            </View>
            {/* <View style={styles.bootomBar}>
                <Text style={{...styles.input3, ...styles.activeDate}}>Intake</Text>
                <Text style={styles.input3}>Inspection</Text>
                <Text style={styles.input3}>Assembly</Text>
                <Text style={styles.input3}>Deployment</Text>
                <Text style={styles.input3}>Reverse Logistics</Text>
                <Text style={styles.input3}>Recycling</Text>
            </View> */}
            <Footer index = {0}/>
        </View>
    )
}

export default Intake