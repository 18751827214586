import { Document, Page, View } from '@react-pdf/renderer'
import React from 'react'
import Header from './Header'
import PageTop from './PageTop'
import Intake from './Intake'
import Report from './Report'
import Assembly from './Assembly'
import Deployment from './Deployment'
import Logistics from './Logistics'
import Recycling from './Recycling'

function ReportPDF() {
    return (
        <Document>
            <Page>
                <Header />
                <View style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <PageTop />
                    <Intake />
                </View>
            </Page>
            <Page>
                <Header />
                <View style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <PageTop />
                    <Report />
                </View>
            </Page>
            <Page>
                <Header />
                <View style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <PageTop />
                    <Assembly/>
                </View>
            </Page>
            <Page>
                <Header />
                <View style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <PageTop />
                    <Deployment/>
                </View>
            </Page>
            <Page>
                <Header />
                <View style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <PageTop />
                    <Logistics/>
                </View>
            </Page>
            <Page>
                <Header />
                <View style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <PageTop />
                    <Recycling/>
                </View>
            </Page>
        </Document>
    )
}

export default ReportPDF