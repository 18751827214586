import React, { useState } from "react";
import SopHeader from "./StepForms/sopHeader";
import Grid from "@mui/material/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "./StepForms/Battery-Data/Pagination";
import { getBulkReport } from "../../service/apiService";
import { toast } from "react-toastify";
import moment from "moment";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import axios from "axios";

interface Document {
  _id: string;
  battery_module: string;
  reportUrl?: string;
}

interface Data {
  docs?: Document[];
  nextPage?: number;
  page?: number;
  pagingCounter?: number;
  prevPage?: number;
  totalDocs?: number;
  totalPages?: number;
}

const BulkDownload: React.FC = () => {
  const [startDate1, setStartDate1] = useState<Date | null>(null);
  const [endDate1, setEndDate1] = useState<Date | null>(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [data, setData] = useState<Data>({});

  const getData = async (pageNumber: number) => {
    if (!startDate1 || !endDate1) {
      toast.error("Please select both start and end dates");
      return;
    }

    const params = {
      page: pageNumber,
      sizePerPage: "100000",
      startDate: moment(startDate1).format("DD/MM/YYYY"),
      endDate: moment(endDate1).format("DD/MM/YYYY"),
    };

    setLoading(true);
    try {
      const res = await getBulkReport(params);
      if (res?.success && res?.data?.data) {
        setData(res.data.data);
      }
    } catch (e) {
      console.error("Error fetching data:", e);
    } finally {
      setLoading(false);
    }
  };

  const downloadFiles = async () => {
    if (data.docs) {
      setDownloading(true);

      const pdfUrls = data.docs
        .filter((doc) => doc.reportUrl)
        .map((doc) => ({
          url: doc.reportUrl,
          filename: `${doc.battery_module}.pdf`,
        }));

      const zip = new JSZip();
      const filePromises = pdfUrls.map(async ({ url, filename }) => {
        if (url) {
          try {
            const response = await axios.get(url, { responseType: "blob" });
            zip.file(filename, response.data);
          } catch (error) {
            console.error(`Error downloading ${filename}:`, error);
          }
        }
      });

      try {
        await Promise.all(filePromises);
        const content = await zip.generateAsync({ type: "blob" });
        saveAs(
          content,
          `${moment(startDate1).format("MM-DD-YYYY")}-${moment(endDate1).format(
            "MM-DD-YYYY"
          )}.zip`
        );
      } catch (error) {
        console.error("Error generating zip file:", error);
      } finally {
        setDownloading(false);
      }
    }
  };

  const handlePageChange = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
    getData(selected + 1);
  };

  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"Report bulk download"} link={""} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box common-kpi">
              <div className="graph_heading">
                <h4>Report download</h4>
              </div>
              <Grid container spacing={3} flexDirection={"column"}>
                <Grid xs={12} item width={"100%"} maxWidth={"100%"}>
                  <div className="graph_heading">
                    <div className="graph_filter">
                      <DatePicker
                        selected={startDate1}
                        showIcon
                        toggleCalendarOnIconClick
                        isClearable
                        onChange={(date: Date | null) => setStartDate1(date)}
                        placeholderText="Start date"
                        className="startDate"
                      />
                      <DatePicker
                        selected={endDate1}
                        showIcon
                        toggleCalendarOnIconClick
                        isClearable
                        onChange={(date: Date | null) => setEndDate1(date)}
                        placeholderText="End date"
                        className="startDate"
                      />
                    </div>
                    <div className="graph_filter">
                      <Button
                        style={{ minHeight: "35px", lineHeight: "22px" }}
                        onClick={() => getData(page)}
                        disabled={loading || downloading}
                      >
                        Search
                      </Button>
                      <Button
                        style={{ minHeight: "35px", lineHeight: "22px" }}
                        onClick={() => downloadFiles()}
                        disabled={loading || downloading}
                      >
                        {downloading ? "Please Wait..." : "Download"}
                      </Button>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div className="table-data">
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Module Id</TableCell>
                        <TableCell align="right">Link</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {loading || downloading ? (
                        <TableCell colSpan={2}>
                          <CircularProgress />
                        </TableCell>
                      ) : data.docs && data.docs.length > 0 ? (
                        data.docs.map((obj) => (
                          <TableRow
                            key={obj._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {obj.battery_module}
                            </TableCell>
                            <TableCell
                              onClick={() =>
                                obj.reportUrl &&
                                window.open(obj.reportUrl, "_blank")
                              }
                              align="right"
                              style={{ cursor: "pointer" }}
                            >
                              {obj.reportUrl}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableCell colSpan={2}>No Data Found</TableCell>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* {data.totalDocs && data.totalDocs > 10 && (
                  <Pagination
                    pageCount={data.totalPages ?? 0}
                    onPageChange={handlePageChange}
                  />
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BulkDownload;
