import {Image, StyleSheet, Text, View} from "@react-pdf/renderer"
import React from "react"
import logo from "../../../../../assets/images/logo-trademark.png"
function Header() {
  const styles = StyleSheet.create({
    page: {},
    section: {
      backgroundColor: "black",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0px 20px",
      height: "60px",
      flexDirection: "row",
    },
    section2: {
      width: "15%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    right: {
      width: "65%",
    },
    text: {
      fontSize: "20px",
      color: "#3498d2",
    },
    logoImage: {
      height: "50px",
      width: "120px",
    },
  })
  return (
    <View style={styles.section}>
      <View style={styles.section2}>
        <Image src={logo} style={styles.logoImage} />
      </View>

      <View style={styles.right}>
        <Text style={styles.text}>Battery Passport</Text>
      </View>
    </View>
  )
}

export default Header
