import React, { Dispatch, SetStateAction, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { fieldmap } from "./fieldmap";
import { useSelector } from "react-redux";

import graySamsar from "../../assets/images/samsaricon-gray.png";
import blueSamsar from "../../assets/images/samsaricon-blue.png";
import checkIcon from "../../assets/svg/check-icon.svg";
import { handleFormData, handleStep } from "../../reducers/inspectionReducer";
import { useAppDispatch } from "../../store/rtctype";
import { Field } from "./types";

export const ProgressSidebar = ({
  type,
  open,
  setOpen,
}: {
  type: string;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
}) => {
  const formData = useSelector((state: any) => state.inspection).formData;

  const { user } = useSelector((state: any) => state.user);
  const { permission, loading } = useSelector((state: any) => state.user);
  console.log("%c Line:35 🍕 permission", "color:#f5ce50", permission);

  const reduxStep = useSelector((state: any) => state.inspection).step;

  //
  useEffect(() => {
    if (
      user?.username === "operator" ||
      user?.username === "myohanna" ||
      user?.username === "hgavi"
    ) {
      // const obj = {...fieldmap}
      delete fieldmap.step_14;
      //
    }
  }, [user]);

  useEffect(() => {
    if (permission) {
      dispatch(handleStep(""));
      delete fieldmap.step_16;
      delete fieldmap.step_14;
      delete fieldmap.step_1;
      delete fieldmap.step_2;
      delete fieldmap.step_3;
      delete fieldmap.step_4;
      delete fieldmap.step_18;
      delete fieldmap.step_11;
      delete fieldmap.step_12;
      delete fieldmap.step_13;
      delete fieldmap.step_15;
      delete fieldmap.step_19;
      delete fieldmap.step_20;
      delete fieldmap.step_21;
      delete fieldmap.step_22;
      delete fieldmap.step_23;
      delete fieldmap.step_24;
      delete fieldmap.step_25;
      delete fieldmap.step_26;
      delete fieldmap.step_27;
      delete fieldmap.step_28;
      if (
        !permission?.find((module: any) => module?.module === "Schedular tool")
          ?.read
      ) {
        delete fieldmap.step_16;
      } else {
        fieldmap.step_16 = {
          title: "Scheduler Dashboard",
          fields: [
            {
              key: "schedule",
              name: "Scheduler Dashboard",
              type: "field",
            },
          ],
        };
      }
      if (!permission?.find((module: any) => module?.module === "KPI")?.read) {
        delete fieldmap.step_14;
      } else {
        fieldmap.step_14 = {
          title: "KPI Dashboard",
          fields: [
            {
              key: "kpi",
              name: "Key Performance Indicator",
              type: "field",
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Operation")?.read
      ) {
        delete fieldmap.step_1;
        delete fieldmap.step_2;
        delete fieldmap.step_3;
        delete fieldmap.step_4;
        delete fieldmap.step_11;
        delete fieldmap.step_12;
        delete fieldmap.step_18;
        delete fieldmap.step_19;
      } else {
        fieldmap.step_1 = {
          title: "Incoming Lot",
          fields: [
            {
              key: "identifier",
              name: "Scan Lot ID",
              type: "field",
            },
            {
              key: "oem",
              name: "OEM",
              type: "select",
              option: [],
              optionVal: "code",
            },
            {
              key: "next_step",
              type: "reset",
              name: "Add Another",
              hidden: true,
              sidenav: false,
            },
          ],
        };
        fieldmap.step_2 = {
          title: "Incoming Package Inspection",
          view_media: [
            {
              type: "image",
              key: "pallet_image",
              name: "Sample Pallet Image",
              src: "pallet_3.png",
            },
          ],
          upload_media: [
            {
              type: "image",
              key: "image",
              name: "Uploaded Pallet Image",
            },
          ],
          form_content: [
            { name: "Pallet Number", key: "identifier" },
            { name: "Pallet Weight", key: "weight" },
          ],
          fields: [
            {
              key: "shipment_lot",
              name: "Scan Lot ID",
              description: "Scan Lot ID",
              type: "select",
              option: [],
              optionVal: "identifier",
            },
            {
              key: "identifier",
              name: "Pallet ID",
              description: "Desc",
              type: "field",
            },
            {
              key: "weight",
              name: "Pallet Weight (lbs)",
              type: "field",
              validate: {
                range: [300, 3000],
              },
            },
            {
              key: "image",
              name: "Pallet Image",
              type: "file",
            },
            {
              key: "next_step",
              type: "reset",
              name: "Add Another",
              hidden: true,
              sidenav: false,
            },
          ],
        };
        fieldmap.step_3 = {
          title: "Inbound WIP",
          fields: [
            {
              key: "shipment_lot",
              name: "Incoming Lot ID",
              type: "select",
              option: [],
              optionVal: "identifier",
            },
            {
              key: "pallet",
              name: "Scan Pallet ID",
              type: "select",
              option: [],
              optionVal: "identifier",
            },
            {
              key: "oem",
              name: "Select OEM",
              type: "select",
              option: ["Nissan", "Toyota", "Honda"],
              optionVal: "code",
            },
            {
              key: "oem_model",
              name: "Select Model",
              type: "select",
              option: ["Leaf Gen 1", "Leaf Gen 2", "Leaf Gen 3", "Leaf Gen 4"],
              optionVal: "code",
            },
            {
              key: "storage_bin",
              name: "Scan Inbound WIP Bin ID",
              type: "field",
            },
            {
              key: "module_count",
              name: "Enter number of modules to be placed in Bin",
              type: "field",
            },
            {
              key: "next_step",
              type: "reset",
              name: "Add Another",
              hidden: true,
              sidenav: false,
            },
          ],
        };

        fieldmap.step_4 = {
          title: "Daily Prep",
          fields: [
            {
              key: "number_of_units",
              name: "Number of Unit",
              type: "field",
            },
            {
              key: "oem",
              name: "Select OEM",
              type: "select",
              option: ["Nissan", "Toyota", "Honda"],
              optionVal: "code",
            },
            {
              key: "oem_model",
              name: "Select Model",
              type: "select",
              option: ["Leaf Gen 1", "Leaf Gen 2", "Leaf Gen 3", "Leaf Gen 4"],
              optionVal: "code",
            },
            {
              key: "bin_id",
              name: "Bin Id",
              type: "field",
            },
            {
              key: "kwy_gen",
              name: "Generate Module Serial Number",
              type: "custom",
              action: "generate_serial_number",
              sidenav: false,
            },
          ],
        };
        fieldmap.step_18 = {
          title: "HIS Progress",
          fields: [
            {
              key: "kpi1",
              name: "Scan Module ID",
              type: "HIS",
            },
          ],
        };
        fieldmap.step_11 = {
          title: "Sorting & Binning Process",
          fields: [
            {
              key: "module_id",
              name: "Scan Module ID",
              type: "field",
            },
          ],
        };

        fieldmap.step_12 = {
          title: "Module Packaging",
          fields: [
            {
              key: "number_of_packs",
              name: "Enter number of packs to be shipped",
              type: "field",
            },
            {
              key: "oem",
              name: "Select Module OEM",
              type: "select",
              option: ["Nissan", "Toyota", "Honda"],
              optionVal: "code",
            },
            {
              key: "oem_model",
              name: "Select Module Model",
              type: "select",
              option: ["Leaf Gen 1", "Leaf Gen 2", "Leaf Gen 3", "Leaf Gen 4"],
              optionVal: "code",
            },
            {
              key: "modules_per_pack",
              name: "Enter number of modules/pack",
              type: "field",
            },
            {
              key: "soh_high",
              name: "Enter SOH High for product",
              type: "field",
            },
            {
              key: "soh_low",
              name: "Enter SOH Low for product",
              type: "field",
            },
            {
              key: "assembly_contractor",
              name: "Select Assembly Contractor",
              type: "select",
              option: ["Alchemy", "Precise Connections"],
            },
            {
              key: "assembly_location",
              name: "Select Assembly location",
              type: "select",
              option: ["HTX", "DTX"],
            },
            {
              key: "voltage",
              name: "Voltage",
              type: "select",
              option: ["15", "29", "50"],
            },
            {
              key: "bill_of_material",
              name: "Bill Of Material(BOM) ID",
              type: "select",
              option: ["001", "002", "003", "004"],
            },
            {
              key: "submit",
              name: "",
              type: "submit",
              sidenav: false,
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Report")?.read
      ) {
        delete fieldmap.step_13;
      } else {
        fieldmap.step_13 = {
          title: "Report",
          fields: [
            {
              key: "gen_report",
              name: "Publish",
              type: "custom",
              action: "publish_report",
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Passport")?.read
      ) {
        delete fieldmap.step_15;
      } else {
        fieldmap.step_15 = {
          title: "Passport",
          fields: [
            {
              key: "gen_passport",
              name: "Publish",
              type: "custom",
              action: "publish_passport",
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Bulk Report")
          ?.read
      ) {
        delete fieldmap.step_20;
      } else {
        fieldmap.step_20 = {
          title: "Report Bulk Download",
          fields: [
            {
              key: "bulk",
              name: "Bulk download",
              type: "field",
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Vendor")?.read
      ) {
        delete fieldmap.step_19;
      } else {
        fieldmap.step_19 = {
          title: "Vendor",
          view_media: [
            {
              type: "image",
              key: "vendor_image",
              name: "Sample Pallet Image",
              src: "new1.jpeg",
            },
          ],
          upload_media: [
            {
              type: "image",
              key: "vendor_image",
              name: "Uploaded Pallet Image",
            },
          ],
          fields: [
            {
              key: "packId",
              name: "Pack ID",
              type: "select",
              option: [""],
              optionVal: "packId",
            },
            {
              key: "rejected_moduleId",
              name: "Reject Pack ID",
              type: "field",
            },
            {
              key: "replace_module",
              name: "Add/replace Pack ID",
              type: "field",
            },
            {
              key: "vendor_image",
              name: "Upload picture",
              type: "file",
            },
            {
              key: "vendor_excel",
              name: "Upload factory acceptance test",
              type: "excel",
            },
            {
              key: "vendor_save",
              name: "Continue",
              type: "custom",
              action: "generate_serial_number",
              sidenav: false,
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Assembler")?.read
      ) {
        delete fieldmap.step_21;
      } else {
        fieldmap.step_21 = {
          title: "Assembler Portal",
          fields: [
            {
              key: "assembler",
              name: "Assembler Portal",
              type: "field",
            },
          ],
        };
      }
      if (
        !permission?.find((module: any) => module?.module === "Customer")?.read
      ) {
        delete fieldmap.step_22;
      } else {
        fieldmap.step_22 = {
          title: "Customer Portal",
          fields: [
            {
              key: "customer",
              name: "Customer Portal",
              type: "field",
            },
          ],
        };
      }

      const moduleSteps = [
        // { module: "QIS Operation", step: "step_23" },
        { module: "Schedular tool", step: "step_16" },
        { module: "Passport", step: "step_15" },
        { module: "Report", step: "step_13" },
        { module: "Bulk Report", step: "step_20" },
        { module: "Assembler", step: "step_21" },
        { module: "Customer", step: "step_22" },
        { module: "Vendor", step: "step_19" },
        { module: "Operation", step: "step_1" },
        { module: "KPI", step: "step_14" },
      ];

      moduleSteps.forEach(({ module, step }) => {
        if (permission?.find((perm: any) => perm?.module === module)?.read) {
          dispatch(handleStep(step));
        }
      });
    }
  }, [permission]);
  // useEffect(() => {
  //   if (reduxStep === "step_15") {
  //     window.open(`/#/passport`, "_blank")
  //   }
  // }, [reduxStep])

  const dispatch = useAppDispatch();

  const handleToStep = (step: string) => {
    // update local storage with current step
    localStorage.setItem("step", step);
    dispatch(handleStep(step));
  };
  return (
    <div className="sm-colm-3">
      {!loading &&
        formData &&
        Object.keys(fieldmap).map((step, index) => {
          if (
            step === "step_23" ||
            step === "step_24" ||
            step === "step_25" ||
            step === "step_26" ||
            step === "step_27" ||
            step === "step_28"
          ) {
            return;
          } else {
            return (
              <>
                <Accordion
                  key={index}
                  className="sm-dark-accordian"
                  expanded={reduxStep === step}
                  onClick={() => {
                    handleToStep(step);
                    if (type === "Drawer" && setOpen) {
                      setOpen(false);
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <img src={checkIcon} alt="" />
                    <Typography>{fieldmap[step]?.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {step === "step_23" ||
                    step === "step_24" ||
                    step === "step_25" ||
                    step === "step_26" ||
                    step === "step_27" ||
                    step === "step_28" ? (
                      <List component="div">
                        {fieldmap[step]?.fields
                          ?.slice(0, 1)
                          ?.map((field: Field, i: number) =>
                            field.sidenav !== false ? (
                              <>
                                <ListItemButton key={i}>
                                  <ListItemText primary={field?.name} />
                                  <ListItemIcon>
                                    {formData[step] &&
                                    formData[step][field?.key] ? (
                                      <img src={blueSamsar} />
                                    ) : (
                                      <img src={graySamsar} />
                                    )}
                                  </ListItemIcon>
                                </ListItemButton>
                              </>
                            ) : null
                          )}
                      </List>
                    ) : (
                      <List component="div">
                        {fieldmap[step]?.fields?.map(
                          (field: Field, i: number) =>
                            field.sidenav !== false ? (
                              <>
                                <ListItemButton key={i}>
                                  <ListItemText primary={field?.name} />
                                  <ListItemIcon>
                                    {formData[step] &&
                                    formData[step][field?.key] ? (
                                      <img src={blueSamsar} />
                                    ) : (
                                      <img src={graySamsar} />
                                    )}
                                  </ListItemIcon>
                                </ListItemButton>
                              </>
                            ) : null
                        )}
                      </List>
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            );
          }
        })}
    </div>
  );
};
