import { Image, StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'
import Footer from './Footer'
function Assembly() {
    const styles = StyleSheet.create({
        section1: {
            flexDirection: "column",
            width: "95%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10,
            border: "2px solid black",
            paddingTop: 10,
        },
        rightSide: {
            width: "80%",
            flexDirection: "column",
            marginTop:10
        },
        inputs: {
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
            gap: 30,
            alignItems: "center",
            width: "100%",
            marginTop: 5
        },
        input: {
            width: 200,
            height: "30px",
            border: "1px solid black"
        },
        input2: {
            width: 200,
            height: "25px",
            border: "1px solid black"
        },
        input3: {
            width: "16.6666666%",
            height: "25px",
            borderTop: "1px solid black",
            borderRight: "1px solid black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection:"row"
        },
        activeDate: {
            backgroundColor: "black",
            color: "white"
        },
        inputText: {
            fontSize: 12,
            fontWeight: 700
        },
        inputText2: {
            fontSize: 10,
            fontWeight: 700
        },
        intakeHead: {
            fontSize: 20,
            fontWeight: 900
        },
        intakeImg: {
            height: 200,
            width: 200
        },
        bootomBar: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            marginTop: 100,
           
        }
    })
    return (
        <View style={styles.section1}>
            <Text style={styles.intakeHead}>Battery Pack Assembly </Text>
            <View style={styles.rightSide}>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Battery Pack QR Code </Text>
                    <Text style={styles.input}></Text>
                </View>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Battery Pack Assembly Date</Text>
                    <Text style={styles.input}></Text>
                </View>
                <View style={styles.inputs}>
                    <Text style={styles.inputText}>Battery Pack Assembly Report Link</Text>
                    <Text style={styles.input}></Text>
                </View>
            </View>
            <Footer index={2} />
        </View>
    )
}

export default Assembly